import { poolData } from '$lib/auth';
import {
  handleErrorWithSentry,
  replayIntegration,
  captureConsoleIntegration,
} from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.VERCEL_ENV || 'development',
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [
    replayIntegration(),
    captureConsoleIntegration({
      levels: ['warn', 'error'],
    }),
  ],
  enabled: process.env.NODE_ENV !== 'development',
});

// Add cognito user to Sentry context
const cognitoUser = new CognitoUserPool(poolData).getCurrentUser();
const session = cognitoUser?.getSession(
  (error: string | null, session: CognitoUserSession | null) => {
    if (error) return;
    return session;
  },
) as CognitoUserSession | void;

if (session) {
  Sentry.setUser({
    id: session?.getIdToken().payload['user_id'],
    email: session?.getIdToken().payload.email,
  });
}
// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
