import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [];

export const dictionary = {
		"/(auth)": [14,[2]],
		"/checkout": [23],
		"/confirm-access/[id]": [24],
		"/dashboard": [25,[3]],
		"/dashboard/comment-reagir": [26,[3]],
		"/dashboard/messages": [27,[3,4]],
		"/dashboard/messages/defend": [28,[3,4,5]],
		"/dashboard/messages/defend/authority": [29,[3,4,5,6]],
		"/dashboard/notifications": [30,[3,7]],
		"/dashboard/parametres": [31,[3,8]],
		"/dashboard/proches": [32,[3,9]],
		"/dashboard/profil": [33,[3,10]],
		"/finaliser": [34,[11]],
		"/formule": [35,[12]],
		"/invitation": [36],
		"/invitation/[id]": [37],
		"/kyc/success": [38],
		"/(auth)/login": [15,[2]],
		"/(auth)/login/reset": [16,[2]],
		"/(auth)/login/restore": [17,[2]],
		"/(auth)/logout": [18,[2]],
		"/(auth)/parent-authorization": [19,[2]],
		"/payment/success": [39],
		"/reseaux-sociaux": [40,[13]],
		"/(auth)/signup": [20,[2]],
		"/(auth)/signup/activation": [21,[2]],
		"/(auth)/signup/missing-fields": [22,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';